font-stack =  Arial, sans-serif


@font-face {
  font-family: "RobotoCondensed";
  src: url("../font/RobotoCondensed-Regular.ttf");
}
@font-face {
  font-family: "RobotoCondensedLight";
  src: url("../font/RobotoCondensed-Light.ttf");
}
.popupButton
    cursor: pointer;

.popup_overlay 
    display: none;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 1111;
    background-color: black;
    -moz-opacity: 0.70;
    -khtml-opacity: 0.70;
    opacity: 0.70;
    background-position: initial;
    background-repeat: initial;
    &.white
        background: transparent;
        z-index: 1110;

.popup_window 
    display: none;
    width: 300px;
    border: 1px solid transparent;
    background: #fff;
    position: fixed;
    z-index: 2222;
    top: 50%;
    left: 50%;
    margin-left: -150px;
    padding: 20px 24px;
    -webkit-box-shadow: 0px 0px 20px 0px rgba(50, 50, 50, 0.75);
    -moz-box-shadow:    0px 0px 20px 0px rgba(50, 50, 50, 0.75);
    box-shadow:         0px 0px 20px 0px rgba(50, 50, 50, 0.75);

    &.recall
        height: 285px;
        margin-top: -145px;

    &.thank
        height: 200px;
        margin-top: -100px;

    .popup_title
        &.centered
            text-align: center;
        position: relative;
        line-height: 18px;
        font-size: 30px;
        letter-spacing: 0.5px;
        font-weight: 500;
        font-family: 'RobotoCondensedLight', 'Roboto', sans-serif;

        .popup_close
            position: absolute;
            right: 0px;
            top: 0px;
            width: 25px;
            height: 25px;
            background: url(../img/popup_close.png) center center no-repeat;
            opacity: 0.7;
            cursor: pointer;
            &:hover
                opacity: 1;
            &:active
                opacity: 0.9;
    
    .popup_content 
        padding: 25px 0;
        &.centered
            text-align: center;
            font-size: 17px;
            line-height: 25px;
            letter-spacing: 0.5px;
            color: #333333;
            padding: 32px 0;
        form
            .field_item
                &.submit
                    margin-top: -4px;
                margin-bottom: 10px;
                input[type='text']
                    width: 100%;
                    font-size: 17px;
                    font-weight: 300;
                    line-height: 25px;
                    padding: 6px 12px;
                    letter-spacing: 0.5px;
                    font-family: 'Roboto', sans-serif;
                    &::-webkit-input-placeholder
                        color: #000;
                    &::-moz-placeholder         
                        color: #000;
                    &:-moz-placeholder          
                        color: #000;
                    &:-ms-input-placeholder     
                        color: #000;
.menu__popup
    display: none;
    width: 100%;
    border: none;
    background: #07502f;
    position: fixed;
    z-index: 2222;
    top: 0;
    height: 100%;
    left: 50%;
    margin-left: -50%;
    padding: 0;
    -webkit-box-shadow: 0px 0px 20px 0px rgba(50, 50, 50, 0.75);
    -moz-box-shadow:    0px 0px 20px 0px rgba(50, 50, 50, 0.75);
    box-shadow:         0px 0px 20px 0px rgba(50, 50, 50, 0.75);

    li.menu__item
        border-bottom: 1px solid #417644;
        line-height: 44px;
        font-size: 19px;
        a
            font-size: 19px;
            color: #fff;
            display: block;
            padding: 0 20px;
            letter-spacing: 0.5px;
            border: none;
        li.menu__item_sub
            display: none;
            background: #efefef;
            border-bottom: 1px solid #98b299;
            a
                display: block;
                color: #39526e;
                font-size: 17px;
                line-height: 24px;
                padding: 10px 10px 10px 40px;
                border: none;
        &.active
            li.menu__item_sub
                display: block;

.law_close
    display: none;
    position: absolute;
    right: 20px;
    top: 20px;
    width: 25px;
    height: 25px;
    background: url(../img/law_close.png) center center no-repeat;
    opacity: 0.7;
    cursor: pointer;
    &:hover
        opacity: 1;
    &:active
        opacity: 0.9;

#sb-title-inner,
#sb-info-inner,
#sb-loading-inner,
div.sb-message
    font-family: 'HelveticaNeue-Light','Helvetica Neue',Helvetica,Arial,sans-serif;
    font-weight: 200;
    color: #fff;
#sb-container
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    display: none;
    visibility: hidden;
    margin: 0;
    padding: 0;
    text-align: left;
#sb-overlay
    position: relative;
    width: 100%;
    height: 100%;
#sb-wrapper
    position: absolute;
    visibility: hidden;
    width: 100px;
#sb-wrapper-inner
    position: relative;
    overflow: hidden;
    height: 100px;
    border: 1px solid #303030;
#sb-body
    position: relative;
    height: 100%;
#sb-body-inner
    position: absolute;
    width: 100%;height: 100%;
#sb-player.html
    overflow: auto;
    height: 100%;
#sb-body img
    border: none;
#sb-loading
    position: relative;
    height: 100%;
#sb-loading-inner
    font-size: 14px;
    line-height: 24px;
    position: absolute;
    top: 50%;
    width: 100%;
    height: 24px;
    margin-top: -12px;
    text-align: center;
#sb-loading-inner span
    display: inline-block;
    padding-left: 34px;
    background: url(loading.gif) no-repeat;
#sb-body,
#sb-loading
    background-color: #060606;
#sb-title,
#sb-info
    position: relative;overflow: hidden;margin: 0;padding: 0;
#sb-title,
#sb-title-inner
    line-height: 26px;
    height: 26px;
#sb-title-inner
    font-size: 16px;
#sb-info,
#sb-info-inner
    line-height: 20px;
    height: 20px;
#sb-info-inner
    font-size: 12px;
#sb-nav
    float: right;
    width: 45%;
    height: 16px;
    padding: 2px 0;
#sb-nav a
    display: block;
    float: right;
    width: 16px;
    height: 16px;
    margin-left: 3px;
    cursor: pointer;
    background-repeat: no-repeat;
#sb-nav-close
    border: none;
    background-image: url('../img/close.png');
#sb-nav-next
    background-image: url('../img/next.png');
#sb-nav-previous
    background-image: url('../img/previous.png');
#sb-nav-play
    background-image: url('../img/play.png');
#sb-nav-pause
    background-image: url('../img/pause.png');
#sb-counter
    float: left;
    width: 45%;
#sb-counter a
    padding: 0 4px 0 0;
    cursor: pointer;
    text-decoration: none;
    color: #fff;
#sb-counter a.sb-counter-current
    text-decoration: underline;
div.sb-message
    font-size: 12px;
    padding: 10px;
    text-align: center;
div.sb-message a:link,
div.sb-message a:visited
    text-decoration: underline;
    color: #fff;

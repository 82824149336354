clearfix()
	zoom 1
	&:after
	&:before
		content ""
		display table
	&:after
		clear both

.clearfix
	clearfix()

border-radius(radius)
	-webkit-border-radius radius
	-moz-border-radius radius
	-ms-border-radius radius
	border-radius radius

border-left-radius(radius)
	-moz-border-radius-topleft radius
	-webkit-border-top-left-radius radius
	border-top-left-radius radius
	-moz-border-radius-bottomleft radius
	-webkit-border-bottom-left-radius radius
	border-bottom-left-radius radius

border-right-radius(radius)
	-moz-border-radius-topright radius
	-webkit-border-top-right-radius radius
	border-top-right-radius radius
	-moz-border-radius-bottomright radius
	-webkit-border-bottom-right-radius radius
	border-bottom-right-radius radius

border-top-radius(radius)
	-moz-border-radius-topleft radius
	-webkit-border-top-left-radius radius
	border-top-left-radius radius
	-moz-border-radius-topright radius
	-webkit-border-top-right-radius radius
	border-top-right-radius radius


border-bottom-radius(radius)
	-moz-border-radius-bottomleft radius
	-webkit-border-bottom-left-radius radius
	border-bottom-left-radius radius
	-moz-border-radius-bottomright radius
	-webkit-border-bottom-right-radius radius
	border-bottom-right-radius radius

//.bradius border-radius(10px);

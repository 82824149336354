@media all and (min-width: 760px) and (max-width: 1170px)
    .width
        width: 100%;
        margin: 0 auto;

    .columns
        padding-bottom: 30px;
        .sidebar
            float: left;
            width: 24.266666666%;
            padding: 0 15px 0 12px;
            margin-right: 5px;
            padding-top: 30px;
        .content
            width: 73.533333333%;
            float: left;

    .mobile__only,
    .header__mobile,
    .swipe__buttons,
    .swipe_content
        display: none;

    .desktop__only
        display: none;
        
    .main_menu
        display: block;

    .scroll__outer
        overflow-x: scroll;

    .header
        display: block;
        .header__logotype
            margin-left: 10px;
        .header__phonebox
            margin-right: 10px;
        .header__switcher
            .switcher__langs
                width: auto;
                .switcher__lang
                    display: none;
                    &.active
                        display: block;
    .frontblock
        padding-left: 330px;
        padding-bottom: 75px;
        background: #fff url("../img/frontblock_768.png") 50px 20px no-repeat;
        .frontblock__links
            padding: 0 0 0 28px;
        .frontblock__body
            padding-left: 28px;
            p
                padding-left: 0;
    .successblock
        padding-top: 10px;

    .node
        .grid__inlines
            .grid__inline
                width: 50%;
                padding: 5px 0 20px;
                &:nth-child(3n)
                    margin-right: 0;
                    width: 50%;

    .laws
        .law
            .law_inner
                &.active
                    margin: -30px -10px;
                    padding: 30px 10px;
                    left: 10px;
                    .law__avatar
                        padding: 0 40px;
            .law__name
                margin-top: 3px;
                margin-bottom: 5px;
                padding: 0 10px;
                a
                    line-height: 32px;
            .law__avatar
                margin-bottom: 19px;
                padding: 0 50px;

    .services
        .service
            padding: 18px 10px;
            .service__img
                background-size: cover;
                right: 10px;
                width: 210px;
                height: 108px;

            &.service_1
                .service__img
                    width: 222px;
                    height: 92px;
    .newsblock
        .newsblock__list
            .newsblock__item
                margin-top: 20px;

    .services__blocks
        .services__block
            min-height: 320px;
            width: 2%;
            width: 49%;
            margin-right: 10px;
            margin-bottom: 10px;
            &:nth-child(2n)
                margin-right: 0;
            &.services__block_0
                min-height: 270px;
            padding: 18px 20px 18px 25px;

    .news
        &.notabledpadding
            padding-right: 0;
        h2
            font-size: 24px;

    .youtubeblock
        iframe
            width: 740px;
            min-height: 350px;

    .aboutcollapse
        color: #999999;
        margin-bottom: 50px;
        

    .contact
        .contactblock
            width: 33%;
        .contactmap
            width: 66%;

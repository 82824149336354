@media all and (max-width: 760px)
    body
        background: none;
        min-width: 320px;
        &.front
            .header + .header__mobile > .header__logotype
                display: none;

            .header__logotype
                margin: 0;
                width: 100%;


    .width
        width: 100%;
        margin: 0 auto;

    .columns
        background: #fff;
        .sidebar
            float: none;
            width: auto;
            padding: 0 15px;
            margin-right: 0;
            padding-top: 30px;
            display: none;
        .content
            width: auto;
            float: none;

    .header,
    .main_menu
        display: none;

    .header__logotype
        margin: 3px auto;
        width: 300px;
        display: block;
        float: none;
        margin-bottom: 15px;

    .mobile__only,
    .swipe__buttons
        display: block;

    .desktop__only
        display: none;

    .scroll__outer
        overflow-x: scroll;

    .swipe_content
        display: none;
        .sidebar
            display: block;
            padding: 0;
            border: none;

    .swipe__buttons
        margin-top: 10px;
        margin-bottom: 0;
        .btn
            padding: 8px;
            cursor: pointer;
            .swipe__buttons_hide
                display: inline-block;
            .swipe__buttons_show
                display: none;
            &.blue
                background: #7b91aa;
                text-align: center;
                color: #fff;
                font-size: 16px;
                font-weight: 300;
                &.active
                    background: #39526e;
                    .swipe__buttons_show
                        display: inline-block;
                    .swipe__buttons_hide
                        display: none;
                img
                    position: relative;
                    top: 3px;
                    margin-left: -5px;

    .breadcrumb
        padding: 2px 10px;

    .header__mobile
        display: block;
        .menu__top
            height: 50px;
            padding: 0 18px;
            line-height: 50px;
            background: url(../img/bg_menu_320.png);
            background-size: cover;
            position: relative;
            &.fixed
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                z-index: 100;
            a
                border: none;
                font-size: 19px;
                font-weight: 300;
                color: #fff;
                letter-spacing: 0.5px;
                text-decoration: none;
            img
                position: relative;
                top: 5px;
            .menu__top_l
                float: left;
                .menu__popup_show
                    display: none;
                .menu__popup_hide
                    display: inline-block;
                &.active
                    .menu__popup_show
                        display: inline-block;
                    .menu__popup_hide
                        display: none;
                img
                    margin-right: 4px;
            .menu__top_r
                float: right;
        .menu__popup
            display: none;
            background-position: top center;
            margin-top: 50px;
            .menu__item
                display: block;
                width: 100%;
                text-align: left;
    .frontblock
        padding-left: 0;
        padding-bottom: 8px;
        background: url('../img/frontblock_320.png') center 4px no-repeat;
        .header__mobile
            padding-top: 176px;
            text-align: center;
            .header__logotype
                display: block;
                float: none;
                .logotype__descr
                    width: 100%;
                    float: none;
                    text-align: center;
            .header__switcher
                display: block;
                margin: 0;
                .switcher__socials
                    display: block;
                    margin: 0;
                    margin-top: 12px;
                    width: 100%;
                .switcher__langs
                    display: block;
                    margin: 0;
                    margin-top: 4px;
                    width: 100%;
        .frontblock__title
            margin: 20px 0 0px;
            padding: 23px 20px 18px;
            line-height: 30px;
            text-align: center;
            border-top: 1px solid #d9e1eb;
        .frontblock__body
            padding: 0 10px;
            text-align: center;
        .frontblock__links
            // margin: 24px 0 30px;
            padding-left: 0;
            text-align: center;
            a
                margin: 0;
                text-align: center;
            .cur
                margin-left: 20px;
                // display: none;

    .successblock
        padding-bottom: 45px;
        .successblock__pretitle
            display: none;
        .successblock__title
            line-height: 40px;
            margin-top: 20px;
            margin-bottom: 30px;
            a
                padding-bottom: 0;
        .successblock__fours
            .successblock__four
                float: none;
                width: 100%;
                min-height: 80px;
                padding: 0 0 0 114px;
                margin: 0;
                margin-bottom: 30px;
                text-align: left;
                &.successblock__four_1
                    background: url(../img/successblock_1_320.png) 12px top no-repeat;
                &.successblock__four_2
                    background: url(../img/successblock_2_320.png) 12px top no-repeat;
                &.successblock__four_3
                    background: url(../img/successblock_3_320.png) 12px top no-repeat;
                &.successblock__four_4
                    background: url(../img/successblock_4_320.png) 12px top no-repeat;
    .services
        .service
            width: 100%;
            float: none;
            display: block;
            &.service_1,
            &.service_2,
            &.service_3,
            &.service_4,
            &.service_5,
            &.service_6
                background: url(../img/service_.png);
                background-size: cover;
            .service__img
                margin-left: -134px;
                left: 50%;
                // right: 25px;
                width: 268px;
                height: 135px;
            &.service_1
                .service__img
                    margin-left: -140px;
                    left: 50%;
                    width: 280px;
                    height: 116px;

    .news
        &.notabledpadding
            padding: 0 10px;
        h1
            padding-bottom: 28px;
            &.service
                padding-right: 0;
                padding-bottom: 35px;
        h2
            font-size: 24px;
        h3
            padding-top: 15px;
    .node
        ol
            padding-bottom: 5px;
            li
                background: none;
                padding-bottom: 0;
        .grid__inlines
            .grid__inline
                width: auto;
                float: none;
                padding: 5px 0 20px;
    
    .services__blocks
        margin: 12px 0;
        .services__block
            float: none;
            width: auto;
            min-height: 320px;
            padding: 20px 25px;
            &.services__block_0
                min-height: 275px;

    .node__description
        padding: 0;
        .node__date
            float: none;
            text-align: left;
            margin-bottom: 10px;
        .node__socials
            float: none;
            text-align: left;
        padding: 0 10px;
        .newsblock
            .newsblock__title
                padding: 0 5px;
            .newsblock__list
                .newsblock__item
                    display: block;
                    width: 100%;
                    padding: 0;
                    .newsblock__text
                        padding-right: 5px;
                        padding-bottom: 42px;

    .newsblock
        .newsblock__title
            padding: 0 15px;
            a
                display: none;
        .newsblock__list
            .newsblock__item
                display: block;
                width: 100%;
                padding: 0 15px 0 10px;
                .newsblock__text
                    padding-bottom: 27px;
            .link_more
                margin-left: 10px;
                font-weight: 500;
                font-size: 17px;

    .youtubeblock
        iframe
            width: 100%;
            padding: 0 10px;
            height: 180px;
            min-height: 180px;

    .aboutcollapse
        margin-bottom: 50px;
        &.collapsed
            .aboutcollapse__text
                height: 120px;
        .aboutcollapse__text
            padding: 10px;

    .contact
        padding-bottom: 70px;
        h1
            margin: 16px 0 34px;
        .contactblock
            float: none; 
            width: auto;
            padding-left: 11px;
            margin-bottom: 35px;
        .contactmap
            float: none;
            width: auto;
    .pager
        ul
            .next,
            .prev
                padding: 0 8px;

    .laws
        .law
            float: none;
            width: 100%;
            margin-bottom: 23px;
            .law_inner
                &.active
                    position: fixed;
                    top: 45px;
                    left: 50%;
                    margin-left: -45%;
                    width: 90%;
            .law__avatar
                margin-bottom: 20px;
            .law__name,
            .law__position
                padding: 0 12px;
                line-height: 30px;

    .popup_overlay 
        &.white
            background-color: black;

    .footer
        padding: 0;
        ul
            margin-top: 24px;
            li
                margin-left: 0;
        .footer__top
            height: auto;
            padding: 25px 10px 27px;
        .footer__bottom
            height: auto;
            padding: 8px 10px;
            .footer__none
                display: none;
            .footer__tmean
                padding: 0;
                margin-top: 12px;
                text-align: left;
                a
                    img
                        margin: 0;
        .footer_menu
            display: none;
        .footer__third
            float: none;
            width: 100%;

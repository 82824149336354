@import url(http://fonts.googleapis.com/css?family=Roboto:300,500&subset=latin,cyrillic);

@require 'nib'
global-reset()

@require 'clearfix'
@require 'varible'
@require 'mixins'

@require 'popups'
@require '1200'
@require '768'
@require '320'


*,
*:before,
*:after
    -webkit-box-sizing border-box
    -moz-box-sizing border-box
    box-sizing border-box
    outline none
    transition opacity .2s 0s, transform .2s 0s, border-color .2s 0s;


a
    color: #315dad;
    text-decoration: none;
    border-bottom: 1px solid #c0cde6;
    font-weight: 300;
    &:hover
        border-color: transparent;
    &.tdn
        border-color: transparent;
        &:hover
            border-bottom: 1px solid #c0cde6;
    &.cur
        border-bottom: 1px dashed #c0cde6;
        &:hover
            color: #097a00;
            border-bottom: 1px dashed #b5d7b2;
    &.img
        border: none;
    &.zoom
        position: relative;
        border-style: dashed;
        margin-left: 25px;
        &:before
            content: "";
            display: inline-block;
            width: 20px;
            left: -18px;
            top: 1px;
            position: absolute;
            height: 20px;
            background: url(../img/icon__zoom.png) left center no-repeat;
    &.greenbtn
        border: none;
        display: block;
        text-align: center;
        line-height: 45px;
        color: #fff;
        font-size: 17px;
        font-weight: 300;
        font-family: 'Roboto', sans-serif;
        text-shadow: 0 1px 1px rgba(0,0,0,0.5);
        transition: background 0.25s;
        background: url(../img/greenbtn.png) center center no-repeat;
        &:hover
            background: url(../img/greenbtn_.png) center center no-repeat;

b
    font-weight: 500;







body
    height: 100%;
    font-family: "Roboto";
    font-weight: 300;
    min-height: 100%;
    position: relative;
    font-size: 14px;
    line-height: 25px;
    background: url(../img/bg_top.jpg) top center no-repeat;

img,
iframe
    max-width: 100%;
    height: auto;

.width
    width: 1200px;
    margin: 0 auto;

.shadow
    -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow:    0px 0px 8px 0px rgba(0, 0, 0, 0.2);
    box-shadow:         0px 0px 8px 0px rgba(0, 0, 0, 0.2);
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;

.mobile__only,
.header__mobile,
.swipe__buttons,
.swipe_content
    display: none;

.desktop__only,
.main_menu
    display: block;

.columns
    background: #fff;
    padding-bottom: 30px;
    .sidebar
        float: left;
        width: 285px;
        padding: 0 15px;
        margin-right: 15px;
        padding-top: 12px;
        margin-top: 18px;
        border-right: 1px solid #e9e9e9;
        .avatar__name
            margin: 8px 0 2px;
            font-weight: 500;
            font-size: 18px;
            line-height: 25px;
            font-family: 'RobotoCondensed', sans-serif;
        .avatar__pos
            color: #666666;
            margin-bottom: 30px;
        .sidebar__menu
            padding-top: 6px;
            margin-top: -23px;
            .sidebar__menu_header
                font-size: 18px;
                line-height: 25px;
                color: #000000;
                font-family: 'RobotoCondensedLight', sans-serif;
                letter-spacing: 0.5px;
                margin: 22px 0 11px;
                a
                    color: #000;
                    border-color: #999;
                    text-decoration: none;
                    &:hover
                        border-color: transparent;
            .sidebar__menu_link
                a
                    font-size: 16px;
                    border: none;
                    display: block;
                    padding-left: 15px;
                    padding-bottom: 5px;
                    margin-bottom: 5px;
                    transition: background .25s;
                    background: url(../img/sidebar__menu__dot.png) left 10px no-repeat;
                    &:hover,
                    &:active,
                    &.active
                        margin: 0 -15px 5px;
                        padding-left: 30px;
                        padding-right: 15px;
                        background: #f6f8f9 url(../img/sidebar__menu__dot.png) 270px center no-repeat;
                        span
                            border-color: transparent;
                    span
                        border-bottom: 1px solid #c0cde6;

    .content
        width: 900px;
        float: left;

.header
    display: block;
    text-align: center;
    height: 100px;
.header__logotype
    float: left;
    margin-left: 15px;
    display: inline-block;
    // width: 300px;
    position: relative;

    .logotype__img
        width: 83px;
        position: relative;
        top: 8px;
        text-align: center;
        float: left;
        margin-right: 9px;
        a
            border: none;
    .logotype__descr
        float: left;
        text-align: left;
        width: 208px;
        margin-top: 23px;
        .logotype__title
            font-size: 18px;
            font-weight: 500;
            font-family: "Roboto";
            color: rgb( 0, 0, 0 );
            text-shadow: 0px 0px 1px rgba(255, 255, 255, 0.2);
        .logotype__links
            color: #7b91aa;
            span
                margin: 0 1px;
            .logotype__link
                display: inline-block;
                font-family: "Roboto";
                font-weight: 300;
                color: #7b91aa;
                font-size: 16px;
                margin: 0 1px;

.header__switcher
    display: inline-block;
    text-align: center;
    margin-right: 15px;
    margin-top: 34px;
    a
        border: none;
    .switcher__socials
        width: 140px;
        vertical-align: top;
        margin-right: 10px;
        display: inline-block;
        .switcher__social
            display: inline-block;
            width: 30px;
            margin: 0 1px;
            height: 30px;
            background: url(../img/socials.png);
            opacity: 0.8;
            border-radius: 100%;
            box-shadow:         0px 1px 1px 0px rgba(50, 50, 50, 0.2);
            &.vk
                background-position: 0 0;
            &.fb
                background-position: -35px 0;
            &.jj
                background-position: -70px 0;
            &.tw
                background-position: -105px 0;
            &:hover
                opacity: 1;
    .switcher__langs
        width: 100px;
        margin-top: 2px;
        margin-right: 20px;
        text-align: center;
        display: inline-block;
        .switcher__lang
            display: inline-block;
            font-family: "Roboto";
            font-weight: 300;
            font-size: 14px;
            span
                color: #7b91aa;
            &:hover,
            &:active,
            &.active
                a
                    color: #000;
            a
                color: #7b91aa;
                text-decoration: none;

.header__phonebox
    margin-top: 23px;
    width: 188px;
    margin-right: 15px;
    display: inline-block;
    float: right;
    text-align: right;
    background: url(../img/header__phone.png) left center no-repeat;
    .phonebox__phone
        font-family: "Roboto";
        color: #7b91aa;
        font-size: 18px;
        line-height: 25px;
        margin-bottom: 1px;
        font-weight: 300;
        strong
            font-weight: 500;
            color: #39526e;
    .phonebox__link
        a
            font-family: "Roboto";
            color: #7b91aa;
            font-size: 16px;
            line-height: 25px;
            text-decoration: none;
            margin-right: 24px;
            font-weight: 300;
            border-bottom: 1px dashed #7b91aa;
            &:hover
                border-bottom: 1px dashed transparent;

.breadcrumb
    padding: 4px 16px;
    background: #f6f8f9;
    border-bottom: 1px solid #e6eaf1;
    position: relative;
    font-size: 13px;
    color: #666666;
    img
        top: 3px;
        position: relative;
    span
        padding: 0 2px;
    ul
        padding: 0;
        li
            display: inline;
            a
                padding: 0;
                border-color: #dbe2ea;
                &:hover
                    border-color: transparent;

ul.menu
    background: url(../img/bg_menu_1200.png) center center no-repeat;
    text-align: center;
    height: 50px;
    line-height: 25px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    border: none;
    li.menu__item
        width: 25%;
        line-height: 50px;
        display: inline-block;
        border: none;
        position: relative;
        background: url(../img/bg_menu_sep.png) right center no-repeat;
        &:hover
            .submenu
                display: block;
            >a
                display: block;
                background: rgba(19, 135, 74, 0.7);
                border-radius: 0;
                padding: 0;
        &:last-child
            background: none;
        .submenu
            display: none;
            position: absolute;
            z-index: 1000;
            width: 100%;
            top: 50px;
            li
                text-align: left;
                line-height: 25px;
                border-bottom: 1px solid #417644;
                background: rgba(7, 80, 47, 0.95);
                &:hover
                    a
                        background: rgba(7, 80, 47, 0.95);
                a
                    padding: 7px 15px;
                    font-size: 15px;
                    border: none;
                    letter-spacing: 0.3px;
                    display: block;
                    text-decoration: none;
                    color: #fff;

        >a
            font-size: 19px;
            border: none;
            color: #fff;
            font-weight: 300;
            text-decoration: none;
            padding: 2% 15%;
            @media all and (min-width: 760px) and (max-width: 1000px)
                padding: 2% 10%;
            border-radius: 18px;
            // transition: background .2s;
            letter-spacing: 0.75px;
            text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
            border: none;
            &:active,
            &.active
                background: rgba(19, 135, 74, 0.7);

.frontblock
    position: relative;
    background: #fff url(../img/frontblock_1200.png) 55px bottom no-repeat;
    border-bottom: 1px solid #d9e1eb;
    padding: 33px 0 33px 515px;
    .frontblock__title
        color: #000;
        font-size: 22px;
        line-height: 25px;
        margin-bottom: 25px;
        font-weight: normal;
        span
            color: #315dad;
    .frontblock__body
        p
            padding: 0 0 9px 16px;
            line-height: 25px;
            font-size: 17px;
            font-weight: 300;
            background: url(../img/bullet.png) 2px 9px no-repeat;
    .frontblock__links
        padding: 25px 0 9px 18px;
        a
            font-size: 17px;
            margin-right: 42px;


.successblock
    background: #fff;
    position: relative;
    text-align: center;
    padding: 15px 0 60px;
    .successblock__pretitle
        font-size: 14px;
        color: #adbfd3;
        border: 1px solid #e7ebf1;
        border-radius: 12px;
        display: inline-block;
        padding: 0px 15px;
        position: relative;
        &:after
            content: "";
            position: absolute;
            display: block;
            bottom: -7px;
            left: 14px;
            width: 10px;
            height: 10px;
            background: url(../img/balloon.png) center center no-repeat;
    .successblock__title
        font-size: 36px;
        line-height: 25px;
        text-align: center;
        margin-top: 3px;
        font-family: 'RobotoCondensedLight', 'Roboto', sans-serif;
        a
            // text-decoration: underline;
            // border: none;
            color: #315dad;
            display: inline-block;
            border-width: 3px;
            padding-bottom: 3px;
            text-decoration: none;
            border-bottom: 3px solid #315dad;
            &:hover
                text-decoration: none;
                border-color: transparent;

    .successblock__fours
        .successblock__four
            width: 25%;
            float: left;
            margin-top: 55px;
            padding: 136px 15px 0;
            font-size: 17px;
            line-height: 25px;
            a
                color: #097a00;
                border-bottom: none;
                &:hover
                    border-bottom: 1px solid #097a00;
            &.successblock__four_1
                background: url(../img/successblock_1.png) top center no-repeat;
            &.successblock__four_2
                background: url(../img/successblock_2.png) top center no-repeat;
            &.successblock__four_3
                background: url(../img/successblock_3.png) top center no-repeat;
            &.successblock__four_4
                background: url(../img/successblock_4.png) top center no-repeat;

.services
    .service
        padding: 18px 24px;
        position: relative;
        height: 240px;
        float: left;
        width: 33.333333333%;
        border: none;
        .service__title
            font-size: 22px;
            line-height: 30px;
            letter-spacing: 0.2px;
            text-shadow: 0px 1px 1px rgba(6, 42, 82, 0.3);
            color: #fff;
            font-family: 'RobotoCondensed', 'Roboto', sans-serif;

        .service__img
            position: absolute;
            bottom: 0;
            right: 36px;
            width: 290px;
            height: 148px;
        &.service_1
            background: url(../img/service_1_.png);
            background-size: cover;
            .service__img
                background: url(../img/service_1.png);
                background-size: cover;
                width: 311px;
                height: 130px;
        &.service_2
            background: url(../img/service_2_.png);
            background-size: cover;
            .service__img
                background: url(../img/service_2.png);
                background-size: cover;
        &.service_3
            background: url(../img/service_3_.png);
            background-size: cover;
            .service__img
                background: url(../img/service_3.png);
                background-size: cover;
        &.service_4
            background: url(../img/service_4_.png);
            background-size: cover;
            .service__img
                background: url(../img/service_4.png);
                background-size: cover;
        &.service_5
            background: url(../img/service_5_.png);
            background-size: cover;
            .service__img
                background: url(../img/service_5.png);
                background-size: cover;
        &.service_6
            background: url(../img/service_6_.png);
            background-size: cover;
            .service__img
                background: url(../img/service_6.png);
                background-size: cover;

.services__blocks
    margin: 15px 0;
    .services__block
        position: relative;
        margin-right: 1%;
        margin-bottom: 12px;
        padding: 25px 30px;
        width: 49.5%;
        min-height: 225px;
        float: left;
        display: block;
        border: none;
        transition: background 0.15s;

        -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
        -moz-box-shadow:    0px 3px 5px 0px rgba(0, 0, 0, 0.1);
        box-shadow:         0px 3px 5px 0px rgba(0, 0, 0, 0.1);

        &.services__block_0
            min-height: 190px;
            background: #f1f4f6 url(../img/services__block_0.png) right top no-repeat;
            .services__text
                color: #666;
            &:hover
                background: #007b3d url(../img/services__block_0_.png) right top no-repeat;
        &.services__block_1
            background: #f1f4f6 url(../img/services__block_1.png) right bottom no-repeat;
        &.services__block_2
            background: #f1f4f6 url(../img/services__block_2.png) right bottom no-repeat;
        &.services__block_3
            background: #f1f4f6 url(../img/services__block_3.png) right bottom no-repeat;
        &.services__block_4
            background: #f1f4f6 url(../img/services__block_4.png) right bottom no-repeat;
        &.services__block_5
            background: #f1f4f6 url(../img/services__block_5.png) right bottom no-repeat;
        &.services__block_6
            background: #f1f4f6 url(../img/services__block_6.png) right bottom no-repeat;
        &:nth-child(2n)
            margin-right: 0;
        &:hover
            background-color: #017239;
            &.services__block_1
                background: #017239 url(../img/services__block_1_.png) right bottom no-repeat;
            &.services__block_2
                background: #017239 url(../img/services__block_2_.png) right bottom no-repeat;
            &.services__block_3
                background: #017239 url(../img/services__block_3_.png) right bottom no-repeat;
            &.services__block_4
                background: #017239 url(../img/services__block_4_.png) right bottom no-repeat;
            &.services__block_5
                background: #017239 url(../img/services__block_5_.png) right bottom no-repeat;
            &.services__block_6
                background: #017239 url(../img/services__block_6_.png) right bottom no-repeat;

            .services__title
                span
                    color: #ffffff;
                    border-color: #50a47a;
            .services__text,
            .services__price
                color: #ffffff;

    .services__title
        text-align: left;
        margin-bottom: 5px;
        letter-spacing: 0.5px;
        font-weight: bold;
        span
            font-size: 22px;
            color: #315dad;
            font-family: 'RobotoCondensedLight', 'Roboto', sans-serif;
            border-bottom: 1px solid rgba(49, 93, 173, 0.4);
    .services__text
        padding-bottom: 30px;
        color: #333;
    .services__price
        font-size: 20px;
        font-family: 'RobotoCondensed', sans-serif;
        position: absolute;
        bottom: 20px;
        color: #bdc5ca;


.newsblock
    padding-bottom: 36px;
    .newsblock__title
        position: relative;
        text-align: center;
        margin: 43px 0 34px;
        font-size: 36px;
        line-height: 25px;
        font-family: 'RobotoCondensedLight', 'Roboto', sans-serif;     
        a
            float: right;
            position: absolute;
            right: 0;
            margin-right: 15px;
            margin-top: 2px;
            font-size: 17px;
            font-family: 'Roboto', sans-serif;
    .newsblock__list
        .newsblock__item
            float: left;
            padding: 0 25px 0 15px;
            width: 33.33333333%;
            &.newsblock__item_nopd
                padding-left: 0;
            .newsblock__date
                font-size: 14px;
                line-height: 20px;
                color: #7b91aa;
                border: 1px solid #e7ebf1;
                border-radius: 12px;
                display: inline-block;
                padding: 0px 10px;
                margin-bottom: 18px;
                position: relative;
                &:after
                    content: "";
                    position: absolute;
                    display: block;
                    bottom: -7px;
                    left: 14px;
                    width: 10px;
                    height: 10px;
                    background: url(../img/balloon.png) center center no-repeat;
            .newsblock__img
                margin-bottom: 8px;
                a
                    border: none;
            .newsblock__text
                padding-bottom: 40px;
                font-size: 17px;
                line-height: 30px;

.news
    background: #fff;
    padding-bottom: 50px;
    padding: 0 15px;
    .newsblock__title
        position: relative;
        text-align: center;
        margin: 24px 0 34px;
        letter-spacing: 0.5px;
        font-size: 32px;
        line-height: 40px;
    h1
        font-size: 32px;
        line-height: 40px;
        padding: 24px 0 32px;
        letter-spacing: 0.5px;
        text-align: center;
        font-family: 'RobotoCondensedLight', 'Roboto', sans-serif;
        &.service
            // padding-top: -5px;
            padding-bottom: 16px;
            text-align: left;
    h2
        font-size: 26px;
        padding: 10px 0 6px;
        line-height: 36px;
        letter-spacing: 0.5px;
        font-family: 'RobotoCondensedLight', 'Roboto', sans-serif;
    h3
        font-size: 22px;
        padding: 10px 0 6px;
        line-height: 32px;
        letter-spacing: 0.5px;  
        font-family: 'RobotoCondensedLight', 'Roboto', sans-serif;
.node
    font-size: 16px;
    line-height: 25px;
    .fixmargin
        height: 2px;
        clear: both;
    p
        margin: 8px 0;
        font-size: 16px;
        line-height: 25px;
        vertical-align: top;
    blockquote
        margin: 20px 0 20px 30px;
        border-left: 3px #7b91aa solid;
        background: #f2f5f8;
        padding: 5px 12px;
    .grid__inlines
        vertical-align: top;
        margin: 12px auto 32px;
        display: inline-block;
        position: relative;
        img
            float: left;
            position: relative;
            top: 0px;
            margin-right: 15px;
        .grid__inline  
            width: 33.333333%;
            padding: 5px 0 10px;
            position: relative;
            display: inline-block;
            vertical-align: top;
    table
        border-collapse: collapse;
        margin: 30px auto 50px;
        position: relative;
        overflow-x: auto;
        &.inline
            tbody,
            thead            
                tr
                    td
                        vertical-align: top;
                        width: 33%;
                        padding-top: 15px;
                        border: none;
                        text-align: left;
                        img
                            float: left;
                            margin-right: 10px;
                            position: relative;
                            top: -3px;
        &.scroll
            &:after
                content: "";
                display: block;
                position: absolute;
                background: url(../img/table.png) center no-repeat;
                width: 100%;
                height: 30px;
        thead
            tr
                padding: 10px;
                background: #e9eef5;
                td
                    color: #778699;
                    border-collapse: collapse;
                    font-weight: 300;
                    border: 1px solid #adbfd3;
                    text-align: center;
                    padding: 6px;
        tbody
            tr
                td
                    color: #414141;
                    font-weight: 300;
                    text-align: center;
                    border-collapse: collapse;
                    border: 1px solid #adbfd3;
                    padding: 8px;

    img
        position: relative;
        top: 3px;
    ol
        padding: 1px 0;
        counter-reset: my-node-counter;
        li
            padding: 5px 0 0px;
            position: relative;
            margin-left: 30px;
            padding-left: 15px;
            background: url(../img/li_dot_back.png) 0px repeat-y;
            &:before
                content: counter(my-node-counter);
                counter-increment: my-node-counter;
                color: #097a00;
                width: 15px;
                text-align: right;
                font-weight: 400;
                line-height: 25px;
                position: absolute;
                left: -27px;
    ul
        padding: 1px 0;
        li
            margin: 5px 0;
            padding-left: 20px;
            background: url('../img/li__dot.png') -7px 1px no-repeat;

.node__description
    padding-bottom: 25px;
    border-bottom: 1px solid #e9e9e9;
    .node__date
        float: left;
        color: #999999;
        font-size: 16px;
    .node__socials
        float: right;
    a
        border: none;

.laws
    background: #fff;
    padding: 0;
    position: relative;
    .law_line
        height: auto;
        transition: height 10000s;
    .law
        position: relative;
        float: left;
        width: 33.3333333%;
        text-align: center;
        margin-top: 2px;
        margin-top: -1px;
        margin-bottom: 40px;
        .law_inner
            &.active
                position: absolute;
                margin: -30px -15px;
                padding: 30px 10px;
                left: 15px;
                width: 100%;
                background: #017239;
                cursor: pointer;
                z-index: 11111;
                transition: background .2s;
                box-shadow:         0px 3px 4px 0px rgba(50, 50, 50, 0.25);
                .law_close
                    display: block;
                .law__text
                    display: block;
                .law__position
                    color: #f7f7f7;
                .law__name
                    a
                        border: none;
                        color: #f7f7f7;
        .popup_close
            display: none;
        .law__avatar
            margin-bottom: 28px;
            img
                cursor: pointer;
                border-radius: 100%;
                box-shadow:         0px 2px 2px 0px rgba(50, 50, 50, 0.25);

        .law__name
            font-family: 'RobotoCondensedLight', 'Roboto', sans-serif;
            letter-spacing: 0.5px;
            a
                font-size: 20px;
                line-height: 40px;
        .law__position
            font-size: 16px;
            line-height: 25px;
            color: #666666;
            padding: 0 2px;
        .law__text
            display: none;
            color: #99d7b8;
            margin-top: 15px;
            font-size: 16px;
            line-height: 25px;

.pager
    text-align: center;
    font-size: 18px;
    line-height: 36px;
    position: relative;
    margin-bottom: 30px;
    ul
        .next,
        .prev
            padding: 0 22px;
        .curr
            color: #000;
            border: 2px solid #c2c2c2;
            border-radius: 3px;
            padding: 0 15px;
        li
            display: inline-block;
            padding: 0 12px;
            a
                display: block;
                border: none;

.youtubeblock
    background: #f2f5f8;
    text-align: center;
    iframe
        width: 71%;
        display: block;
        min-height: 512px;
        margin: 55px auto;
        -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
        -moz-box-shadow:    0px 0px 8px 0px rgba(0, 0, 0, 0.2);
        box-shadow:         0px 0px 8px 0px rgba(0, 0, 0, 0.2);

.aboutcollapse
    color: #999999;
    margin-bottom: 50px;
    .aboutcollapse__text
        transition: height 0.3s;
        line-height: 20px;
        font-size: 15px;
        padding: 8px 15px;
        position: relative;
        overflow: hidden;
        p
            margin-bottom: 8px;
    .aboutcollapse__button
        text-align: center;
        font-size: 15px;
        color: #315dad;
        a
            border-style: dashed;
    &.collapsed
        .aboutcollapse__text
            height: 128px;
            &:after
                bottom: 0;
                left: 0;
                content: "";
                display: block;
                width: 100%;
                position: absolute;
                height: 80px;
                background: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1));

.preview
    max-width: 400px;
    padding: 20px;
    margin: 0 auto;
    h1
        font-size: 32px;
        line-height: 40px;
        text-align: center;
        margin: 25px 0 32px;
        font-family: 'RobotoCondensedLight', 'Roboto', sans-serif;


.contact
    background: #fff;
    padding-bottom: 80px;
    h1
        font-size: 32px;
        line-height: 40px;
        text-align: center;
        margin: 25px 0 32px;
        font-family: 'RobotoCondensedLight', 'Roboto', sans-serif;
    .contactblock
        float: left;
        width: 420px;
        padding-right: 20px;
        font-size: 16px;
        padding-left: 15px;
        label
            font-weight: 300;
            color: #333333;
            display: block;
            margin-top: 3px;
        .contactblock__phone
            padding-left: 45px;
            background: url(../img/contactblock_phone.png) left 3px no-repeat;
            color: #666666;
            margin-bottom: 14px;
        .contactblock__address
            background: url(../img/contactblock_house.png) left 5px no-repeat;
            color: #666666;
            padding-left: 45px;
            margin-bottom: 14px;
        .contactblock__email
            background: url(../img/contactblock_mail.png) 2px 8px no-repeat;
            color: #666666;
            padding-left: 45px;
    .contactmap
        float: left;
        width: 780px;


.footer
    color: #7b91aa;
    .footer__top
        font-size: 16px;
        line-height: 25px;
        background: #e5ecf3;
        height: 190px;
        padding: 22px 14px;
    .footer__bottom
        font-size: 14px;
        background: #d3dde7;
        height: 40px;
        padding: 9px 13px;
        position: relative;
        a
            border-bottom: 1px solid transparent;
            img
                position: relative;
                top: -2px;
                margin-top: 4px;
                margin-left: 20px;

    .footer__third
        width: 33.333333333%;
        float: left;

    .footer__tmean
        a
            position: relative;
            top: -6px;
            color: #7b91aa;
            font-size: 12px;
            font-family: 'Roboto', sans-serif;
            font-weight: 300;
            text-shadow: 1px 0px 1px rgba(255, 255, 255, 0.3);
            img
                top: 4px;

    .footer__phone
        padding-left: 30px;
        background: url(../img/footer_phone.png) left 3px no-repeat;
        margin-bottom: 10px;
    .footer__address
        background: url(../img/footer_house.png) left 5px no-repeat;
        padding-left: 30px;
        margin-bottom: 10px;
    .footer__email
        background: url(../img/footer_mail.png) 2px 8px no-repeat;
        padding-left: 30px;
    ul
        li
            margin-left: 20px;
            padding-left: 12px;
            margin-bottom: 10px;
            &.vk
                text-indent: 22px;
                background: url(../img/footer_socials.png) 0 7px no-repeat;
            &.fb
                text-indent: 22px;
                background: url(../img/footer_socials.png) 0 -28px no-repeat;
            &.jj
                text-indent: 22px;
                background: url(../img/footer_socials.png) 0 -63px no-repeat;
            &.tw
                text-indent: 22px;
                background: url(../img/footer_socials.png) 0 -98px no-repeat;
    a
        color: #39526e;
        line-height: 25px;
        font-size: 16px;
        text-decoration: none;
        border-bottom: 1px solid #bdc8d4;
        &:hover
            border-bottom: 1px solid transparent;
